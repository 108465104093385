<template>
  <section class="head">
    <div class="content">
      <div class="top">
        <h1>{{ projectData.taskName }}&nbsp;</h1>
        <p class="time">
          <ClockCircleOutlined />
          {{ dateFormat(projectData.startTime) }} ~
          {{ dateFormat(projectData.endTime) }}
        </p>
        <p class="complet" v-if="completCondition.length">
          <!-- 完成条件 -->
          {{ $t("CM_CCondition") }}：<span
            class="tag"
            v-for="(item, index) in completCondition"
            :key="index"
          >
            {{ index + 1 }}.{{ item }}
          </span>
        </p>
        <!-- <p class="intro" v-if="projectData.intro">{{ projectData.intro }}</p> -->
      </div>
      <div class="bottom">
        <div class="person" v-if="projectData.createUser">
          <div class="avatar">
            <img :src="projectData.portrait" alt="avatar" />
          </div>
          <div>
            <div class="name">
              <OpenData type="userName" :openid="projectData.createUser" />
            </div>
            <div class="label">{{ $t("project.assign_personnel") }}</div>
            <!-- 指派人 -->
          </div>
        </div>
        <div class="classify">
          <template v-for="item in resourceTypes" :key="item.id">
            <div class="item" v-if="item.extraArr.length">
              <div class="label">{{ item.name }}</div>
              <div class="value">
                <strong>{{ item.extraArr.length }}</strong>
                {{ item.unit }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>

  <!-- <section class="step">
    <div class="progress"></div>
    <div class="next">继续学习</div>
  </section> -->

  <a-spin :spinning="loadding">
    <section class="mian">
      <div class="stage" v-if="stagesData.length > 1">
        <a-anchor wrapperClass="stage-menu">
          <a-anchor-link
            v-for="(item, index) in stagesData"
            :key="item.stageId"
            :href="'#stage' + (index + 1)"
          >
            <template v-slot:title>
              <!-- 阶段 -->
              <span class="name">{{ $t("project.stages", [index + 1]) }}</span>
              <span class="num">{{ item.details.length || 0 }}</span>
            </template>
          </a-anchor-link>
        </a-anchor>
      </div>
      <div class="detail" :class="{ full: stagesData.length == 1 }">
        <a-tabs v-model:activeKey="activeKey">
          <!-- 详情介绍 -->
          <a-tab-pane key="1" :tab="$t('project.details')">
            <div style="padding: 14px 30px 10px 30px">
              <div
                v-if="projectData.intro"
                class="v-html"
                v-html="projectData.intro"
              ></div>
              <div style="padding: 40px 0" v-else>
                <a-empty :description="$t('LB_NoIntroduction')" />
                <!-- 暂无介绍 -->
              </div>
            </div>
          </a-tab-pane>
          <!-- 培训目录 -->
          <a-tab-pane key="2" :tab="$t('project.catalog')">
            <div class="directory" v-if="stagesData.length">
              <div
                v-for="(stage, index) in stagesData"
                :key="stage.stageId"
                :id="'stage' + (index + 1)"
              >
                <div class="title">
                  <div class="headline">
                    <h5>
                      <!-- <span>阶段{{ index + 1 }}</span> -->
                      {{ stagesData[index].stageName }}
                    </h5>
                    <span class="schedule">
                      {{ stagesData[index].progress }}%
                    </span>
                  </div>
                  <div class="intro" v-if="stagesData[index].intro">
                    {{ stagesData[index].intro }}
                  </div>
                </div>
                <ul class="list">
                  <li
                    v-for="item in stagesData[index].details"
                    :key="item.detailId"
                    :class="{ lock: !item.learnFlag }"
                    @click="jump(item, stagesData[index].stageId)"
                  >
                    <div class="tag-box">
                      <span
                        class="tag float"
                        v-if="
                          item.resourceType == 4 ||
                          item.resourceType == 5 ||
                          item.resourceType == 7
                        "
                      >
                        {{ resourceType[item.resourceType - 1].name }}
                      </span>
                      <span class="tag" v-else>
                        {{ resourceType[item.resourceType - 1].name }}
                      </span>
                    </div>
                    <div class="info">
                      <h5>
                        {{ item.detailName }}
                        <sup v-if="item.isRequired == 1" style="color: red">{{
                          $t("CM_Required")
                        }}</sup>
                        <sup v-else style="color: #3582fb">{{
                          $t("CM_Choose")
                        }}</sup>
                      </h5>
                      <div class="other">
                        <!-- 积分/学分/学时 -->
                        <span class="pd-10" v-if="item.studyIntegral"
                          >{{ $t("LB_Pro_Integral") }}：{{
                            item.studyIntegral
                          }}</span
                        >
                        <span class="pd-10" v-if="item.studyScore"
                          >{{ $t("CM_LB_Credit") }}：{{ item.studyScore }}</span
                        >
                        <span class="pd-10" v-if="item.studyTime"
                          >{{ $t("CM_Period") }}：{{ item.studyTime }}</span
                        >
                      </div>
                      <div class="bottom">
                        <span>
                          <!-- 时间 -->
                          {{ $t("Pub_Tab_Time") }}：{{
                            dateFormat(projectData.startTime)
                          }}
                          -
                          {{ dateFormat(projectData.endTime) }}
                        </span>
                      </div>
                      <div class="bottom">
                        <span v-if="item.resourceType == 3">
                          {{ $t("project.require") }}：{{
                            $t(
                              item.exam && item.exam.examPass == 2
                                ? "project.passing_the_exam"
                                : "ExamsAreCompletedWhenYouHandInYourPapers"
                            )
                          }}
                          <!-- 要求：考试成绩及格后才可过关 -->
                        </span>
                      </div>
                    </div>
                    <div class="status">
                      <template v-if="item.learnFlag">
                        <template v-if="item.resourceType == 3">
                          <span
                            style="color: #2cbc15"
                            v-if="item.complete == 1"
                          >
                            <img src="@/assets/image/kczj-dui.png" alt="" />
                            {{ $t("CM_Completed") }}
                            <!-- 已完成 -->
                          </span>
                          <span
                            style="color: #ff6200"
                            v-else-if="item.waitMarking == 1"
                          >
                            {{ $t("XB_Exam_PendingApproval") }}
                            <!-- 待批阅 -->
                          </span>
                          <span
                            style="color: #666"
                            v-else-if="item.progress == 0 && item.complete == 0"
                          >
                            {{ $t("Pub_Tab_NotStart") }}
                            <!-- 未开始 -->
                          </span>
                          <span
                            style="color: #dd6161"
                            v-else-if="
                              item.exam.examPass == 2 && item.qualifier == 2
                            "
                          >
                            {{ $t("CM_NotCompleted") }}
                            <!-- 未完成 -->
                          </span>
                          <span style="color: #3996fe" v-else>
                            {{ $t("XB_UnderWay") }}
                            <!-- 进行中 -->
                          </span>
                        </template>
                        <template v-else>
                          <span
                            style="color: #2cbc15"
                            v-if="item.complete == 1"
                          >
                            <img src="@/assets/image/kczj-dui.png" alt="" />
                            {{ $t("CM_Completed") }}
                            <!-- 已完成 -->
                          </span>
                          <span
                            style="color: #666"
                            v-else-if="item.progress == 0 && item.complete == 0"
                          >
                            {{ $t("Pub_Tab_NotStart") }}
                            <!-- 未开始 -->
                          </span>
                          <span style="color: #3996fe" v-else>
                            {{ $t("XB_UnderWay") }}
                            <!-- 进行中 -->
                          </span>
                        </template>
                      </template>
                      <span style="color: #999" v-else>
                        {{ $t("not_unlocked") }}
                        <!-- 未解锁 -->
                      </span>
                    </div>
                    <div
                      v-if="item.resourceType == 3"
                      :class="['exam-status', `exam-status-${item.qualifier}`]"
                    ></div>
                  </li>
                </ul>
              </div>
            </div>
          </a-tab-pane>
          <!-- 学习评论 -->
          <a-tab-pane
            v-if="hasComment"
            key="3"
            :tab="$t('project.comments') + '（' + commentParams.Total + '）'"
            forceRender
          >
            <div style="padding: 14px 30px 10px 30px">
              <my-comment
                v-if="commentParams.resourceId > 0"
                :CommentParams="commentParams"
              ></my-comment>
            </div>
          </a-tab-pane>
          <!-- 培训须知 -->
          <template #tabBarExtraContent>
            <div class="notice-btn">
              <a-button
                type="primary"
                v-if="projectData.notice"
                @click="showNotice"
                >{{ $t("TrainingNotes") }}</a-button
              >
            </div>
          </template>
        </a-tabs>
      </div>
      <!-- <a-affix
        :offset-top="offsetTop"
        style="position: absolute; top: 200px; right: 50%; text-align: right"
      >
        <div style="transform: translateX(682px)">
          <div
            style="
              margin-bottom: 11px;
              border: 1px solid #e6e6e6;
              border-radius: 4px;
              padding: 2px;
              background: #fff;
            "
          >
            <a class="float" @click="clickFavorite()">
              <StarOutlined v-if="!projectData.isFavorite" />
              <StarFilled v-else style="color: #ff9900" />
            </a>
            <a class="float" @click="activeKey = '3'">
              <MessageOutlined />
            </a>
          </div>
          <div
            style="
              border: 1px solid #e6e6e6;
              border-radius: 4px;
              padding: 2px;
              background: #fff;
            "
          >
            <a class="float" @click="backTop()">
              <UpOutlined />
            </a>
          </div>
        </div>
      </a-affix> -->
    </section>
  </a-spin>

  <homework ref="homeworkRef" @updata:homework="homeworkChange" />
  <a-modal
    v-model:visible="noticeVisible"
    :closable="false"
    :keyboard="false"
    :maskClosable="false"
    :width="670"
    wrapClassName="notice-modal"
  >
    <div class="notice-bg">
      <div class="notice-bg-circle"></div>
      <div class="notice-bg-circle"></div>
      <div class="notice-bg-circle"></div>
      <div class="notice-bg-circle"></div>
      <div class="title">{{ $t("TrainingNotes") }}</div>
    </div>
    <div class="notice-main" v-html="projectData.notice"></div>
    <template #footer>
      <div class="center-txt">
        <a-button type="primary" :disabled="noticeS > 0" @click="confirmNotice">
          {{ $t("CM_Confirm") }}
          <span v-show="noticeS"> ({{ noticeS }}s) </span>
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref, getCurrentInstance, watch, reactive, toRefs } from "vue";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import { useStore } from "vuex";
import { dateFormat, sleep } from "@/utils/tools";
import { resourceType } from "@/utils/business";
import { newstaffDetail, handleNoticeRead } from "@/api/newstaff";
import { packageFavorite, star } from "@/api/package";
import myComment from "@/components/my-comment";
import OpenData from "@/components/OpenData.vue";
import homework from "@/components/homework/index.vue";
export default {
  components: {
    "my-comment": myComment,
    OpenData,
    homework,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const taskId = parseInt(route.query.id || 0);

    let loadding = ref(true),
      hasComment = ref(false),
      activeKey = ref("2"),
      offsetTop = 250,
      projectData = ref({}),
      stagesData = ref([]),
      completCondition = ref([]),
      activeStage = ref(0),
      resourceTypes = ref([]),
      commentParams = ref({
        page: 1,
        pageSize: 10,
        resourceId: taskId || 0,
        type: 2, //0知识 1课程 2任务 3新闻
        taskType: 37, //任务类型参数 34培训项目 35培训包 37新员工 38课程专题
        Total: 0,
      });

    watch(
      () => store.getters.companyInfo,
      (company) => {
        hasComment.value = company.useComment == 1;
      },
      { immediate: true }
    );

    const getDetail = async () => {
      await sleep(500);
      newstaffDetail(taskId).then((res) => {
        loadding.value = false;
        if (res.ret === 0) {
          let d = res.data,
            nowTime = new Date().getTime();
          d.remainderDay = Math.floor((d.endTime * 1000 - nowTime) / 86400000);
          d.remainderHours = Math.floor((d.endTime * 1000 - nowTime) / 3600000);
          d.collaboratorArr = JSON.parse(d.collaborator);
          resourceTypes.value = JSON.parse(JSON.stringify(resourceType));
          if (d.stages.length && d.stages[0].details)
            d.stages[0].checked = true;
          for (let i = 0; i < d.stages.length; i++) {
            //项目未逾期  -- 逾期后可学 isOverdue 1 可学
            if ((d.complete != 3 && d.remainderDay >= 0) || d.isOverdue === 1) {
              //阶段是否可学
              if (d.learnOrder == 2) {
                d.stages[i].learnFlag = true;
              } else {
                if (i == 0) {
                  d.stages[i].learnFlag = true;
                } else {
                  if (d.stages[i - 1].progress == 100) {
                    d.stages[i].learnFlag = true;
                  }
                }
              }
              d.stages[i].details = d.stages[i].details || [];
              for (var j = 0; j < d.stages[i].details.length; j++) {
                resourceTypes.value[
                  d.stages[i].details[j].resourceType - 1
                ].extraArr.push(d.stages[i].details[j]);
                //阶段明细是否可学
                if (d.stages[i].learnFlag) {
                  if (d.stages[i].learnOrder == 2) {
                    d.stages[i].details[j].learnFlag = true;
                  } else {
                    if (j == 0) {
                      d.stages[i].details[j].learnFlag = true;
                    } else {
                      if (d.stages[i].details[j - 1].progress == 100) {
                        d.stages[i].details[j].learnFlag = true;
                      }
                    }
                  }
                }
              }
            } else {
              //阶段是否可学
              if (d.stages[i].progress == 100) {
                d.stages[i].learnFlag = true;
              } else {
                if (i == 0) {
                  d.stages[i].learnFlag = true;
                }
              }
              d.stages[i].details = d.stages[i].details || [];
              for (var j = 0; j < d.stages[i].details.length; j++) {
                resourceTypes.value[
                  d.stages[i].details[j].resourceType - 1
                ].extraArr.push(d.stages[i].details[j]);
                //阶段明细是否可学
                if (d.stages[i].details[j].progress == 100) {
                  d.stages[i].details[j].learnFlag = true;
                }
              }
            }
          }
          projectData.value = d;
          stagesData.value = d.stages || [];
          completCondition.value = [];
          if (projectData.value.requiredNum > 0) {
            completCondition.value.push(
              $t("n_mandatory_resources_need_to_be_completed", {
                number: projectData.value.requiredNum,
              })
            );
          }
          if (projectData.value.electiveNum > 0) {
            completCondition.value.push(
              $t("n_elective_resources_need_to_be_completed", {
                number: projectData.value.electiveNum,
              })
            );
          }

          if (d.isFrame === 1 && d.notice && !d.isNotice) {
            showNotice(1);
          }
        } else {
          router.replace({
            path: `/error`,
            query: {
              title: res.msg,
              // 抱歉，您访问的新人培训不存在。
              path: "/newstaff",
              name: $t("cm_newstaff"),
              // 新人培训
            },
          });
        }
      });
    };
    getDetail();

    const receiveMessage = (e) => {
      if (e.origin !== location.origin) return;
      if (e.data == "reload-project") {
        getDetail();
      }
    };

    window.addEventListener("message", receiveMessage, false);

    onBeforeRouteLeave((to, from, next) => {
      window.removeEventListener("message", receiveMessage, false);
      next();
    });

    const homeworkRef = ref(null);
    const currHomework = ref({});
    let urlStr = "";
    const jump = (item, sid) => {
      if (item.learnFlag) {
        switch (item.resourceType) {
          case 1:
            urlStr = `/doc/detail?taskId=${taskId}&sid=${sid}&did=${item.detailId}`;
            if (store.getters.companyInfo.useNewWindow == 1) {
              window.open(urlStr + "&ddtab=true");
            } else {
              router.push({
                path: "/doc/detail",
                query: {
                  taskId: taskId,
                  sid: sid,
                  did: item.detailId,
                },
              });
            }
            break;
          case 2:
            urlStr = `/course/detail?taskId=${taskId}&sid=${sid}&did=${item.detailId}`;
            if (store.getters.companyInfo.useNewWindow == 1) {
              window.open(urlStr + "&ddtab=true");
            } else {
              router.push({
                path: "/course/detail",
                query: {
                  taskId: taskId,
                  sid: sid,
                  did: item.detailId,
                },
              });
            }
            break;
          case 3:
            router.push({
              path: "/exam/detail",
              query: {
                id: taskId,
                did: item.detailId,
              },
            });
            break;
          case 4:
            router.push({
              path: "/questionnaire/detail",
              query: {
                source: "newstaff",
                id: taskId,
                did: item.detailId,
                resourceId: item.resourceId,
                progress: item.progress,
              },
            });
            break;
          case 5:
            currHomework.value = item;
            homeworkRef.value.openModal({
              id: taskId,
              did: item.detailId,
            });
            break;
          case 7:
            router.push({
              path: "/questionnaire/detail",
              query: {
                source: "newstaff",
                id: taskId,
                did: item.detailId,
                resourceId: item.resourceId,
                progress: item.progress,
              },
            });
            break;
        }
      } else {
        if (
          projectData.value.complete == 3 ||
          projectData.value.remainderDay < 0
        ) {
          proxy.$message.warning($t("project.project_overdue"));
          // 项目已逾期
        } else {
          proxy.$message.warning($t("not_unlocked"));
          // 未解锁
        }
      }
    };

    const homeworkChange = (status) => {
      getDetail();
    };

    //收藏
    const clickFavorite = () => {
      packageFavorite({ id: taskId, type: 37 }).then((data) => {
        if (data.ret == 0) {
          projectData.value.isFavorite = data.data;
          if (projectData.value.isFavorite) {
            proxy.$message.success($t("LB_Doc_FavoriteSuccess"));
            // 收藏成功
            projectData.value.favorites = projectData.favorites + 1;
          } else {
            proxy.$message.success($t("CM_CancelFavoriteSuccess"));
            // 取消收藏成功
            projectData.value.favorites = projectData.favorites - 1;
          }
        }
      });
    };

    //评分
    const rateScore = (score) => {
      star({ id: taskId, star: score }).then((data) => {
        if (data.ret == 0) {
          proxy.$message.success($t("course.thank_you_for_your_rating"));
          // 感谢您的评分！
          projectData.value.myStar = score;
          projectData.value.starUsers += 1;
          projectData.value.starScore = projectData.starScore + score; // 总分数
        }
      });
    };

    const backTop = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    };

    const state = reactive({
      noticeVisible: false,
      noticeTimer: null,
      noticeS: 0,
      isAuto: false,
    });
    const showNotice = (auto) => {
      state.noticeVisible = true;
      if (auto === 1) {
        state.isAuto = true;
        state.noticeS = 5;
        state.noticeTimer = setInterval(() => {
          if (state.noticeS === 0) {
            clearInterval(state.noticeTimer);
            state.noticeTimer = null;
            return false;
          }
          state.noticeS -= 1;
        }, 1000);
      }
    };
    const confirmNotice = () => {
      state.noticeVisible = false;
      if (state.isAuto) {
        state.isAuto = false;
        handleNoticeRead({ id: taskId });
      }
    };

    return {
      dateFormat,
      resourceType,
      loadding,
      hasComment,
      projectData,
      stagesData,
      completCondition,
      activeStage,
      resourceTypes,
      commentParams,
      jump,
      activeKey,
      offsetTop,
      clickFavorite,
      rateScore,
      backTop,
      homeworkRef,
      homeworkChange,
      ...toRefs(state),
      showNotice,
      confirmNotice,
    };
  },
};
</script>

<style lang="less">
.stage-menu {
  background-color: #fff;
  border-radius: 6px;
  padding: 20px;
  margin-top: 24px;
  .ant-anchor-ink {
    &::before {
      width: 0;
      height: 0;
    }
    .ant-anchor-ink-ball {
      display: none !important;
      opacity: 0 !important;
    }
  }
  .ant-anchor-link {
    padding: 0;
    margin-bottom: 12px;
    &-title {
      display: block;
      height: 64px;
      .mixinFlex(space-between; center);
      background-color: #eeeeee;
      padding: 12px 0 12px 24px;
      cursor: pointer;
      border-radius: 6px;
      transition: all 0.2s;
      .name {
        font-size: 16px;
        width: calc(100% - 54px);
        margin: 0;
        .mixinEllipsis(40px);
        transition: all 0.2s;
      }
      .num {
        font-size: 16px;
        width: 44px;
        border-left: 1px solid #666666;
        padding-left: 8px;
        line-height: 0.8;
        transition: all 0.2s;
        font-family: DINAlternate-Bold, DINAlternate;
      }
    }
    &.ant-anchor-link-active,
    &:hover {
      .ant-anchor-link-title {
        background-color: @color-theme;
        .name {
          color: #fff;
        }
        .num {
          color: #fff;
          border-left-color: #fff;
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
<style lang="less" scoped>
.head {
  // height: 370px;
  background-image: url("../../assets/image/project_detail_banner.png");
  // background-position: center;
  // background-repeat: repeat-y;
  background-size: cover;
  padding-top: 82px;
  .content {
    .mixinWrap();
    .top {
      // height: 136px;
      padding-bottom: 10px;
      border-left: 10px solid #fff;
      color: #fff;
      padding-left: 18px;
      line-height: 1.5;
      h1 {
        color: #fff;
        font-size: 32px;
        margin: 0;
        .mixinEllipsis(48px);
      }
      .time {
        font-size: 20px;
        margin: 2px 0 0 0;
        .mixinFlex(flex-start; center);
        font-family: PingFangSC-Regular, PingFang SC;
        span {
          margin-right: 8px;
        }
      }
      .complet {
        font-size: 14px;
        margin: 8px 0 0 0;
        span {
          padding-right: 12px;
        }
      }
      .intro {
        font-size: 14px;
        margin: 8px 0 0 0;
        // .mixinEllipsis(42px; 2);
      }
    }
    .bottom {
      .mixinFlex(space-between; center);
      color: #fff;
      margin-top: 10px;
      .person {
        .mixinFlex(space-between; center);
        .avatar {
          .mixinImgWrap(40px; 40px);
          border-radius: 50%;
          margin-right: 12px;
        }
        .name {
          font-size: 18px;
        }
        .label {
          font-size: 12px;
          color: #ddd;
        }
      }
      .classify {
        .mixinFlex(space-between; center);
        .item {
          font-size: 12px;
          padding: 0 28px;
          position: relative;
          .value {
            strong {
              font-size: 40px;
              font-weight: normal;
              margin-right: 10px;
            }
          }
          &::after {
            content: "";
            width: 2px;
            height: 10px;
            background-color: #fff;
            position: absolute;
            top: 16px;
            right: -1px;
          }
          &:last-child {
            padding-right: 0;
            &::after {
              width: 0;
              height: 0;
            }
          }
        }
      }
    }
  }
}

.step {
  .mixinWrap();
  height: 120px;
  background-color: #fff;
  border-radius: 6px;
  margin-top: -60px;
  padding: 30px;
  .mixinFlex(space-between; center);
  .next {
    width: 160px;
    height: 50px;
    background-color: #e63450;
    border-radius: 6px;
    font-size: 22px;
    color: #fff;
    .mixinFlex(center; center);
    cursor: pointer;
  }
}

.float {
  width: 66px;
  height: 66px;
  display: block;
  font-size: 26px;
  font-weight: 400;
  color: #282828;
  background: #fff;
  text-align: center;
  .anticon {
    padding: 21px 8px;
    border-bottom: 1px solid #f5f5f5;
  }
}
.float:nth-last-child(1) .anticon {
  border-bottom: none;
}

.mian {
  padding-bottom: 64px;
  .mixinWrap();
  .mixinFlex(space-between);
  .stage {
    width: 282px;
  }
  .detail {
    width: 894px;
    background-color: #fff;
    border-radius: 6px;
    margin-top: 24px;
    ::v-deep(.ant-tabs-nav-wrap) {
      padding: 0 30px;
    }
    ::v-deep(.ant-tabs-nav .ant-tabs-tab) {
      padding: 16px 0px;
      font-size: 16px;
      color: #999;
    }
    ::v-deep(.ant-tabs-nav .ant-tabs-tab-active) {
      color: @color-theme;
    }
    ::v-deep(.ant-tabs-ink-bar) {
      background-color: @color-theme;
    }
    &.full {
      width: 100%;
    }
    .directory {
      padding: 14px 30px 10px 30px;
      .title {
        background-color: #f5f5f6;
        padding: 20px 24px;
        .headline {
          .mixinFlex(space-between; center);
          h5 {
            font-size: 16px;
            margin: 0;
            line-height: 1.5;
            width: calc(100% - 50px);
            span {
              padding-right: 16px;
            }
          }
          .schedule {
            font-size: 18px;
            font-family: DINAlternate-Bold, DINAlternate;
          }
        }
        .intro {
          margin-top: 6px;
          font-size: 14px;
        }
      }
      .list {
        li {
          position: relative;
          .mixinFlex(space-between);
          border-bottom: 1px solid #eaf0ee;
          padding: 22px 24px 22px 38px;
          cursor: pointer;
          .tag {
            width: 40px;
            height: 22px;
            border: 1px solid @color-theme;
            color: @color-theme;
            font-size: 14px;
            border-radius: 2px;
            .mixinFlex(center; center);
          }
          .tag-box {
            width: 100px;
            position: relative;
            .tag {
              width: max-content;
              height: 22px;
              padding: 0 4px;
              border: 1px solid @color-theme;
              color: @color-theme;
              font-size: 14px;
              border-radius: 2px;
              .mixinFlex(center; center);
            }
            .float {
              position: absolute;
              left: 0;
              top: 0;
            }
          }
          .info {
            width: calc(100% - 150px);
            h5 {
              font-size: 16px;
              line-height: 22px;
              word-break: break-all;
            }
            .bottom {
              color: #666;
              font-size: 14px;
            }
          }
          .status {
            font-size: 14px;
            width: 80px;
            text-align: right;
            img {
              width: 15px;
              height: 13px;
              vertical-align: -1px;
            }
          }
          &.lock {
            // .noevents();
            .tag {
              border: 1px solid #999;
              color: #999;
            }
            .info {
              h5 {
                color: #999;
              }
              .bottom {
                color: #999;
              }
            }
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
    .rate {
      line-height: 16px;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 15px;
    }
  }
}
.exam-status {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
}
.exam-status-1 {
  background: url("~@/assets/image/exam-pass.png") no-repeat;
  background-size: 100% 100%;
}
.exam-status-2 {
  background: url("~@/assets/image/exam-nopass.png") no-repeat;
  background-size: 100% 100%;
}
.exam-status-3 {
  background: url("~@/assets/image/exam-referee.png") no-repeat;
  background-size: 100% 100%;
}
.other {
  margin: 5px 0;
}
.pd-10 {
  padding-right: 10px;
}

.notice-btn {
  padding: 8px;
}
.notice-modal {
  .notice-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 77px;
    background: linear-gradient(
      116.57deg,
      rgba(81, 168, 250, 1) 0%,
      rgba(120, 217, 255, 1) 100%
    );
    overflow: hidden;
    &-circle {
      position: absolute;
      &:nth-child(1) {
        left: 0;
        bottom: -40px;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        opacity: 0.6;
        background: linear-gradient(
          156.42deg,
          rgba(186, 222, 255, 1) 0%,
          rgba(204, 204, 204, 0) 100%
        );
      }
      &:nth-child(2) {
        left: 219px;
        top: -41px;
        width: 82px;
        height: 82px;
        border-radius: 50%;
        opacity: 0.3;
        background: linear-gradient(
          225.27deg,
          rgba(186, 222, 255, 1) 0%,
          rgba(204, 204, 204, 0) 100%
        );
      }
      &:nth-child(3) {
        right: 88px;
        bottom: 9px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        opacity: 0.4;
        background: linear-gradient(
          136.12deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(204, 204, 204, 0) 100%
        );
      }
      &:nth-child(4) {
        right: 0;
        top: -20px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        opacity: 0.5;
        background: linear-gradient(
          134.77deg,
          rgba(224, 240, 255, 1) 0%,
          rgba(204, 204, 204, 0) 100%
        );
      }
    }
    .title {
      line-height: 77px;
      font-size: 20px;
      font-weight: 600;
      color: #fff;
      text-align: center;
    }
  }
  .notice-main {
    height: 350px;
    margin-top: 77px;
    overflow-y: auto;
  }
  .center-txt {
    text-align: center;
  }
}
</style>

import request from '@/utils/request';

// 获取新人培训列表
export const newstaffList = params => request.post(`study/newstaff/list`, params)

// 获取新人培训详情
export const newstaffDetail = id => request.get(`study/newstaff/${id}/detail`)

// 培训须知已读
export const handleNoticeRead = params => request.post(`study/newstaff/notice`, params)
